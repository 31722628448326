import axios from "axios";

const instance = axios.create({
  // baseURL: "https://us-central1-universal-trade-backend.cloudfunctions.net/app/free-coin",
  // baseURL: "https://us-central1-universal-trade-backend.cloudfunctions.net/app/assetfmdc",
  // baseURL: "https://us-central1-universaltraderscore-27616.cloudfunctions.net/app/free-coin"
  // baseURL : "http://localhost:3000/free-coin",
  baseURL: "https://us-central1-universaltraderscore-dbc88.cloudfunctions.net/app/free-coin/",
});

export default instance;
